import SupplyPage from 'components/pages/supply-chain-financing/SupplyPage';
import Seo from 'components/Seo';
import { graphql } from 'gatsby';
import * as React from 'react';

const Index = () => (
	<div>
		<Seo
			title='Finansowanie łańcucha dostaw | FINTEQ'
			keywords='zapytanie ofertowe, wypełnij formularz, co to finteq, odkryj finansowanie dostawców, kto to animator, oferta finteq, jak zostać animatorem, korzyści finteq'
			description='Odkryj korzyści jakie niesie za sobą finansowanie łańcucha dostaw. Wypełnij krótki formularz kontaktowy i poznaj naszą ofertę.'
			lang='pl-PL'
			locale='pl_PL'
		/>

		<SupplyPage />
	</div>
);

export default Index;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: {language: {eq: $language}}) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
